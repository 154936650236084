<template>
    <div class="news-detail full-width" v-if="translates && translates[langUrl]">
        <div class="news-detail__photo-container" :style="`background-image: url(${ imageSrc(item.picture) })` ">
            <div class="news-detail__title"> {{ item.title }} </div>
            <div class="news-detail__preview">{{ item.preview }}</div>
            <div class="news-detail__photo-container__opacity"></div>
        </div>
        <div class="news-detail__main-container">
            <div class="news-detail__main-container__row">
                <div class="news-detail__news-info">
                    <!-- <div class="news-detail__date">{{ postTime(item.createdAt) }}</div> -->
                    <template v-for="(content, index) in item.contents">
                        <div :key="index" class="news-detail__content">
                            <div
                                v-if="content.type === NEWS_CONTENT_TYPE.TEXT"
                                class="news-detail__text"
                                v-html="content.value"></div>
                            <div
                                v-if="content.type === NEWS_CONTENT_TYPE.PICTURE"
                                class="news-detail__text news-detail__picture">
                                <img :src="imageSrc(content.value)" />
                            </div>
                            <div
                                v-if="content.type === NEWS_CONTENT_TYPE.YOUTUBE"
                                class="news-detail__text">
                                <youtube
                                    class="news-detail__youtube"
                                    :video-id="youtubeVideoId(content.value)" />
                            </div>
                        </div>
                    </template>                    
                </div>
                <NewsPreview
                    v-if="isDesktop"
                    class="column"
                    :title="translates[langUrl].otherNews[lang]"
                    :need-news="2"
                />
            </div>
            <div class="news-detail__main-container__social">
                <div class="news-detail__main-container__social__share">{{ translates[langUrl].detailPage_share[lang] }}</div>
                <div class="news-detail__main-container__social__item facebook"></div>
                <div class="news-detail__main-container__social__item VK"></div>
                <div class="news-detail__main-container__social__item telegram"></div>
                <div class="news-detail__main-container__social__item instagram"></div>
            </div>
            <div class="news-detail__main-container__suitable-tours__title">{{ translates[langUrl].detailPage_suitableTours[lang] }}</div>
            <Splide
                v-if="trips.length > 4 && isDesktop"
                class="news-detail__main-container__suitable-tours_splide"
                style="margin-top: 30px;"
                :options="{ rewind: true, type: 'loop', perPage: tourSplideCounter, perMove: 1, pagination: false }"
            >
                <template v-for="(trip, index) in trips">
                    <SplideSlide :key="index">
                        <MobilePreview
                            :trip="trip"
                            style="width: 335px; height: 98%;"
                            class="trip-preview-card__desktop"
                            @favoriteAdd="favoriteAdd"
                            @favoriteDelete="favoriteDelete"
                            @setStatus="setStatus" />
                    </SplideSlide>
                </template>
            </Splide>
            <div v-if="trips.length <= 4 && isDesktop" class="news-detail__main-container__suitable-tours">
                <template v-for="(trip, index) in trips">
                    <MobilePreview
                        :key="index"
                        :trip="trip"
                        class="trip-preview-card__desktop"
                        @favoriteAdd="favoriteAdd"
                        @favoriteDelete="favoriteDelete"
                        @setStatus="setStatus" />
                </template>
            </div>
            <div v-if="!isDesktop" class="news-detail__main-container__suitable-tours">
                <template v-for="(trip, index) in trips">
                    <MobilePreview
                        :key="index"
                        :trip="trip"
                        style="width: 100%;"
                        class="trip-preview-card__desktop"
                        :buttons="['favorites']"
                        @favoriteAdd="favoriteAdd"
                        @favoriteDelete="favoriteDelete"
                        @setStatus="setStatus" />
                </template>
            </div>
            <BaseButton
                style="width: 240px; margin-top: 10px"
                @click="tripList">{{ translates[langUrl].detailPage_showAllTours[lang] }}</BaseButton>

            <NewsPreview
                v-if="!isDesktop"
                class="column"
                title="Другие новости"
                :need-news="2"
            />
        </div>
    </div>
</template>

<script>
    import NewsPreview from '../../components/common/NewsPreview'
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import MobilePreview from '../../components/common/trip/PreviewCard'
    import BaseButton from '../../components/common/BaseButton'

    import store from '@/store';
    import { isMobile, SplideSlideCounter } from '@/helpers';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE, NEWS_CONTENT_TYPE } from '@/vars';
    import moment from '@/plugins/moment';

    export default {
        name: 'NewsDetail',
        metaInfo () {
            return {
                title: this.item.title,
                meta: [{
                    vmid: 'description',
                    name: 'description',
                    content: this.item.preview,
                }],
            }
        },
        components: {
            NewsPreview,
            Splide,
            SplideSlide,
            MobilePreview,
            BaseButton
        },
        data: () => ({
            TRIP_STATUS,
            NEWS_CONTENT_TYPE,
            imageSrc,
            isDesktop: false,
            title: undefined,
            tourSplideCounter: 1,
            langUrl: 'News'
        }),
        computed: {
            ...mapState('news', {
                news: state => state.entities,
                item: state => state.entity
            }),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('auth', ['user']),
            options () {
                return {
                    offset: 100
                }
            }
        },
        // async beforeRouteEnter (to, from, next) {
        //     if (to.params.code) {
        //         await store.dispatch('news/getByCode', { code: to.params.code });
        //     }
        //     next();
        // },
        async mounted() {
            this.$root.$emit('preloaderShow');
            if (this.$route.params.code) {
                await store.dispatch('news/getByCode', { code: this.$route.params.code });
                this.title = this.item.title;
                await this.getTrips();
            }
            this.$root.$emit('preloaderHide');
        },
        async updated() {
            if (this.item.code !== this.$route.params.code) {
                await store.dispatch('news/getByCode', { code: this.$route.params.code });
                await this.getTrips();
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.tourSplideCounter = SplideSlideCounter(350, 1400);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        // created () {
        //     window.addEventListener('scroll', this.handleScroll);
        // },
        // destroyed () {
        //     window.removeEventListener('scroll', this.handleScroll);
        // },
        methods: { 
            async getNews() {
                await store.dispatch('news/fetch', {});
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting });
            },
            postTime(date) {
                return moment(date).calendar()
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            youtubeVideoId(link) {
                return link ? link.substring(link.indexOf('=') + 1) : '';
            },
            price (value) {
                return new Intl.NumberFormat('ru-RU').format(value);
            },
            async newsList() {
                await this.$router.push({ name: 'news-list' });
            },
            async tripList() {
                await this.$router.push({ name: 'trip-list' });
            },
            async favoriteAdd({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }
            },
            async favoriteDelete({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
        }
    }
</script>

<style lang="scss">
.news-detail {
    width: 100%;
    background: #FFFFFF;
	overflow: hidden;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    padding-bottom: 70px;
        &__photo-container {
            position: relative;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 30vw;
            max-height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media all and (max-width: 768px) {
                height: 410px;
                width: 100%;
            }
            &__opacity {
                position: absolute;
                width: 100%;
                height: 100%;
                background: #021922;
                opacity: .7;
            }
        }
        &__title {
            margin-bottom: 24px;
            font-weight: bold;
            font-size: 56px;
            line-height: 132%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            max-width: 1080px;
            z-index: 10;
            @media all and (max-width: 768px) {
                font-weight: 800;
                font-size: 24px;
                line-height: 150%;
            }
        }
        &__preview {
            max-width: 950px;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
            color: #FFFFFF;
            z-index: 10;
            @media all and (max-width: 768px) {
                font-size: 16px;
                line-height: 150%;
                margin: 0 20px;
            }
        }
        &__date {
            margin-top: 45px;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #93A3B4;
            margin-bottom: 5px;
            @media all and (max-width: 768px) {
                margin-top: 24px;
            }
        }
        &__text {
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #273155;
            img {
                height: 100%;
                max-height: 600px;
                border-radius: 12px;
            }
        }
        &__picture {
            display: flex;
            justify-content: center;
            margin-bottom: 32px;
        }
        &__youtube {
            width: 100%;
            height: 40vw;
            border-radius: 12px;
            overflow: hidden;
        }
    &__news-info {
        width: 100%;
        max-width: 950px;
        @media all and(max-width: 1430px) {
          padding: 32px;  
        }
    }
    &__main-container {
        display: flex;
        flex-direction: column;
        align-content: center;
        @media all and (max-width: 768px) {
            margin: 0 20px;
        }
        @media all and (min-width: 1280px) {
            margin: auto;
            max-width: 1400px;
        }
        &__row {
            display: flex;
            flex-direction: row;
            align-content: space-between;
            @media all and (max-width: 768px) {
            flex-direction: column;
            align-content: center;
        }
        }
        &__social {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;
            margin-bottom: 70px;
            &__share {
                margin-right: 10px;
            }
            &__item {
                width: 32px;
                height: 32px;
                background-position: center;
                background-repeat: no-repeat;
                border: 1px solid #93A3B4;
                border-radius: 50%;
                box-sizing: border-box;
                margin-right: 12px;
                cursor: pointer;
                &:last-of-type {
                    margin-right: none;
                }
                &.facebook {
                    background-image: url(../../assets/Facebook.svg);
                }
                &.VK{
                    background-image: url(../../assets/VK.svg);
                }
                &.telegram {
                    background-image: url(../../assets/Telegram.svg);
                    background-position: 6px 8px;
                }
                &.instagram {
                    background-image: url(../../assets/Instagram.svg);
                }
            }
        }
        &__suitable-tours {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 30px;
            gap: 20px;
            @media all and (max-width: 786px) {
                flex-direction: column;
                align-items: center;
            }
            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 130%;
                letter-spacing: -0.02em;
                color: #273155;
            }
            &_splide {
            .splide__slide {
                align-items: center;
            }
        }
        }
    }
}
</style>